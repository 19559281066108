import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {
  Button,
  Spinner,
  Accordion,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../Auth/LoginSlice";
import {
  setActiveKey,
  setImgVerifyReport,
  setActiveMenu,
  setNewActiveKey,
} from "../Common/ActiveKeySlice";
import Refresh from "../../Assets/Refresh.svg";

const Menubar = () => {
  const userState = useSelector((state) => state.surveylogin.userData.data);
  const activeKeyRedux = useSelector(
    (state) => state.surveyActiveData.activeNumberKey
  );
  const activeNewRedux = useSelector(
    (state) => state.surveyActiveData?.newDashboard
  );

  const activeMenu = useSelector((state) => state.surveyActiveData?.activeMenu);

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute("data-visible");
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = "hidden";
    } else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = "auto";
    }
  }

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(null));
    dispatch(setImgVerifyReport(null));
    localStorage.removeItem("persist:root");
    navigate("/");

    setLoader(false);
  };

  const entering = (e) => {
    e.children[0].style.borderTopColor = "white";
    e.children[1].style.backgroundColor = "white";
    e.children[0].style.borderRadius = "12px";
    e.children[1].style.borderRadius = "12px";
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  const handleNewAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setNewActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  const handleMenuChange = (activeKey, tabName, event) => {
    dispatch(
      setActiveMenu({
        activeKey: activeKey,
        activeTab: tabName,
      })
    );
    if (activeKey === 0) {
      event.stopPropagation();
    }
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className="PopupData">
          <div>
            <Icon.Person className="me-1" size={18} />
            <p>
              <strong>Name - </strong>
              {userState?.name}
            </p>
          </div>

          <div>
            <Icon.Briefcase className="me-2" size={14} />
            <p>
              <strong>Role - </strong>
              {userState?.userType_keyname.replace(
                /^./,
                userState?.userType_keyname[0].toUpperCase()
              )}
            </p>
          </div>
          <div>
            <Icon.Telephone className="me-2" size={14} />
            <p>
              <strong>Mobile - </strong>
              {userState?.mobile}
            </p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <nav>
      <Link className="d-flex align-items-center">
        <h6 className="mb-0 ms-2 d-block d-md-none">
          {activeMenu !== undefined && activeMenu.activeKey === 1
            ? activeNewRedux?.activeTab
            : activeKeyRedux?.activeTab}
        </h6>
        <h5 className="mb-0 ms-2 d-none d-md-block">
          {activeNewRedux?.activeTab}
        </h5>
        <abbr title="Refresh">
          <img
            src={Refresh}
            alt="refresh"
            className="ms-2 refresh"
            style={{ width: "22px" }}
            onClick={() => {
              window.location.reload(false);
            }}
          />
        </abbr>
      </Link>

      <div className="d-flex align-items-center">
        {/* <abbr title='Refresh' className='me-2 d-block d-lg-none'>
            <img src={Refresh} alt="refresh" className='me-2 refresh' style={{width:'30px'}} onClick={()=>{ window.location.reload(false)}}/>
          </abbr> */}
        <Icon.PersonFill
          className="me-2 d-block d-lg-none"
          size={35}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/dashboard/profile`);
            dispatch(
              setActiveKey({
                activeKey: activeKeyRedux.activeKey,
                activeTab: "Profile",
              })
            );
          }}
        />

        <div
          className="mobile-nav-toggle"
          aria-controls="selectNav"
          aria-expanded="false"
          onClick={setnav}
        >
          <div className="menu-btn__burger"></div>
        </div>
      </div>

      <ul className="lg-nav">
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={Popup}
          onEntering={entering}
        >
          <div
            className="d-flex"
            style={{ border: "none", cursor: "pointer" }}
            onClick={() => {
              navigate(`/dashboard/profile`);
              dispatch(
                setActiveKey({
                  activeKey: activeKeyRedux.activeKey,
                  activeTab: "Profile",
                })
              );
            }}
          >
            <Icon.PersonFill className="me-2" size={35} />
            <div>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  marginBottom: "-4px",
                }}
              >
                {userState?.name}
              </p>
              <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                {userState?.userType_name}
              </p>
            </div>
          </div>
        </OverlayTrigger>

        {/* <OverlayTrigger key='bottom' placement='bottom' 
          overlay={ <Tooltip id='bottom' > Refresh </Tooltip> }> */}
        {/* </OverlayTrigger> */}
      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">
        <div className="d-flex justify-content-between flex-column h-100">
          {userState?.userType_keyname === "admin" && (
            <div>
              <Accordion flush defaultActiveKey={activeMenu?.activeKey}>
                {/* Old Dashboard Accordion */}
                <Accordion.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuChange(0, "Old Dashboard", event);
                  }}
                >
                  <Accordion.Header
                    className={
                      activeMenu?.activeKey === 0 ? "newMenu" : "newMenu"
                    }
                  >
                    Mahila Survey Dashboard{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      flush
                      defaultActiveKey={activeKeyRedux?.activeKey}
                    >
                      <Accordion.Item
                        eventKey={1}
                        onClick={() => {
                          handleAccordionChange(1, 1, "Dashboard");
                          navigate("/dashboard");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 1 ? (
                            <Icon.HouseDoorFill className="me-2" />
                          ) : (
                            <Icon.HouseDoor className="me-2" />
                          )}
                          Home
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={2}
                        onClick={() => {
                          handleAccordionChange(2, 2, "Manage Users");
                          navigate("/dashboard/manage-users");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 2 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Manage Users
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={3}
                        onClick={() => {
                          handleAccordionChange(
                            3,
                            3,
                            "Mahilawise Report (Contractors)"
                          );
                          navigate("/dashboard/mahilawise-report");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 3 ? (
                            <Icon.PersonCircle className="me-2" />
                          ) : (
                            <Icon.PersonCircle className="me-2" />
                          )}
                          Mahilawise Report
                        </Accordion.Header>
                      </Accordion.Item>

                      {/* -------------- Surveys -----------------*/}
                      <Accordion.Item
                        eventKey={6}
                        className={
                          activeKeyRedux?.activeKey === 6
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleAccordionChange(
                              6,
                              activeKeyRedux.subActiveKey,
                              activeKeyRedux.activeTab
                            );
                          }}
                        >
                          {activeKeyRedux?.activeKey === 6 ? (
                            <Icon.BarChartFill className="me-2" />
                          ) : (
                            <Icon.BarChart className="me-2" />
                          )}
                          Surveys
                        </Accordion.Header>
                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              handleAccordionChange(6, 6.1, "Manage Surveys");
                              navigate("/dashboard/manage-surveys");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.1 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2" />
                            )}
                            Manage Surveys
                          </Button>

                          <Button
                            onClick={() => {
                              handleAccordionChange(6, 6.2, "Weekly Report");
                              navigate("/dashboard/daywise-report");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.2 ? (
                              <Icon.CalendarRangeFill className="me-2" />
                            ) : (
                              <Icon.CalendarRange className="me-2" />
                            )}
                            Weekly Report
                          </Button>

                          <Button
                            onClick={() => {
                              handleAccordionChange(6, 6.3, "Nearby Report");
                              navigate("/dashboard/nearby-report");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.3
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.3 ? (
                              <Icon.GeoAltFill className="me-2" />
                            ) : (
                              <Icon.GeoAlt className="me-2" />
                            )}
                            Nearby Report
                          </Button>

                          <Button
                            onClick={() => {
                              handleAccordionChange(6, 6.4, "Survey Images");
                              navigate("/dashboard/survey-images");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.4
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.4 ? (
                              <Icon.ImageFill className="me-2" />
                            ) : (
                              <Icon.Image className="me-2" />
                            )}
                            Survey Images
                          </Button>

                          {/* <Button onClick={() => { handleAccordionChange(6, 6.5, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); }}
className={activeKeyRedux?.subActiveKey===6.5 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
{activeKeyRedux?.subActiveKey === 6.5 ? <Icon.CartCheckFill className='me-2' /> : <Icon.CartCheck className='me-2' />}Image Verification
</Button> */}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={7}
                        onClick={() => {
                          handleAccordionChange(7, 7, "Re-Survey");
                          navigate("/dashboard/manage-re-survey");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 7 ? (
                            <Icon.BarChartFill className="me-2" />
                          ) : (
                            <Icon.BarChart className="me-2" />
                          )}
                          Manage Re-Survey
                        </Accordion.Header>
                      </Accordion.Item>

                      {/* -------------- Fire Officers -----------------*/}
                      <Accordion.Item
                        eventKey={4}
                        className={
                          activeKeyRedux?.activeKey === 4
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleAccordionChange(
                              4,
                              activeKeyRedux.subActiveKey,
                              activeKeyRedux.activeTab
                            );
                          }}
                        >
                          {activeKeyRedux?.activeKey === 4 ? (
                            <Icon.Fire className="me-2" />
                          ) : (
                            <Icon.Fire className="me-2" />
                          )}
                          Fire Officer
                        </Accordion.Header>

                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              handleAccordionChange(
                                4,
                                4.1,
                                "Fire Officer Report"
                              );
                              navigate("/dashboard/fireofficer-report");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 4.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 4.1 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2" />
                            )}
                            Fire Officer Report
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/verification-report");
                              handleAccordionChange(
                                4,
                                4.2,
                                "Fire Officer's Verification Report"
                              );
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 4.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 4.2 ? (
                              <Icon.ShieldFillCheck className="me-2" />
                            ) : (
                              <Icon.ShieldCheck className="me-2" />
                            )}
                            Verification Report
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* -------------- Mavims / Sahyogini -----------------*/}
                      <Accordion.Item
                        eventKey={5}
                        className={
                          activeKeyRedux?.activeKey === 5
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleAccordionChange(
                              5,
                              activeKeyRedux.subActiveKey,
                              activeKeyRedux.activeTab
                            );
                          }}
                        >
                          {activeKeyRedux?.activeKey === 5 ? (
                            <Icon.PersonBoundingBox className="me-2" />
                          ) : (
                            <Icon.PersonBoundingBox className="me-2" />
                          )}
                          Sahyogini
                        </Accordion.Header>
                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              handleAccordionChange(5, 5.1, "Sahyogini Report");
                              navigate("/dashboard/mavim-report");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 5.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 5.1 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2" />
                            )}
                            Sahyogini Report
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/image-verification-report");
                              handleAccordionChange(
                                5,
                                5.2,
                                "Sahyogini Image Verification"
                              );
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 5.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 5.2 ? (
                              <Icon.ImageFill className="me-2" />
                            ) : (
                              <Icon.Image className="me-2" />
                            )}
                            Image Verify Report
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/rejected-report");
                              handleAccordionChange(
                                5,
                                5.3,
                                "Admin Rejected List"
                              );
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 5.3
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 5.3 ? (
                              <Icon.XCircleFill className="me-2" />
                            ) : (
                              <Icon.XCircle className="me-2" />
                            )}{" "}
                            Admin Rejected List
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/promoter-verify");
                              handleAccordionChange(
                                5,
                                5.4,
                                "Promoter Verification"
                              );
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 5.4
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 5.4 ? (
                              <Icon.BadgeAdFill className="me-2" />
                            ) : (
                              <Icon.BadgeAd className="me-2" />
                            )}{" "}
                            Promoter Verification
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={10}
                        onClick={() => {
                          handleAccordionChange(
                            10,
                            10,
                            "Survey Image Verification"
                          );
                          navigate("/dashboard/survey-image-verification");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 10 ? (
                            <Icon.ShieldFillCheck className="me-2" size={17} />
                          ) : (
                            <Icon.ShieldCheck className="me-2" size={17} />
                          )}
                          Image Verification
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={8}
                        onClick={() => {
                          handleAccordionChange(8, 8, "SMS Campaign Report");
                          navigate("/dashboard/sms-campaign");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 8 ? (
                            <Icon.EnvelopeFill className="me-2" size={17} />
                          ) : (
                            <Icon.Envelope className="me-2" size={17} />
                          )}
                          SMS Campaign
                        </Accordion.Header>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush defaultActiveKey={activeMenu?.activeKey}>
                {/* New Dashboard Accordion */}
                <Accordion.Item
                  onClick={(event) =>
                    handleMenuChange(1, "New Dashboard", event)
                  }
                >
                  <Accordion.Header
                    className={
                      activeMenu?.activeKey === 1 ? "newMenu" : "newMenu"
                    }
                  >
                    Officer Survey Dashboard{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      flush
                      defaultActiveKey={activeNewRedux?.activeKey}
                    >
                      <Accordion.Item
                        eventKey={11}
                        onClick={() => {
                          handleNewAccordionChange(11, 11, "Dashboard");
                          navigate("/dashboard");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 11 ? " activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 11 ? (
                            <Icon.HouseDoorFill className="me-2" />
                          ) : (
                            <Icon.HouseDoor className="me-2" />
                          )}
                          Officer Home
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={12}
                        onClick={() => {
                          handleNewAccordionChange(
                            12,
                            12,
                            "Officer Manage Users"
                          );
                          navigate("/dashboard/officer-manage-users");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 12 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 12 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Officer Manage Users
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={14}
                        onClick={() => {
                          handleNewAccordionChange(14, 14, "Manage Surveys");
                          navigate("/dashboard/officer-manage-survey");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 14 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 14 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Manage Surveys
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={15}
                        onClick={() => {
                          handleNewAccordionChange(15, 15, "Officer Report");
                          navigate("/dashboard/officer-report");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 15 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 15 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Officer Report
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={2}
                        onClick={() => {
                          handleNewAccordionChange(2, 2, "School Letter");
                          navigate("/dashboard/school-letter");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 2 ? " activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 2 ? (
                            <Icon.EnvelopePaperFill className="me-2" />
                          ) : (
                            <Icon.EnvelopePaper className="me-2" />
                          )}
                          School Letter
                        </Accordion.Header>
                      </Accordion.Item>

                      {/* -------------- Hospital -----------------*/}
                      <Accordion.Item
                        eventKey={22}
                        className={
                          activeNewRedux?.activeKey === 22
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleNewAccordionChange(
                              22,
                              activeNewRedux.subActiveKey,
                              activeNewRedux.activeTab
                            );
                          }}
                        >
                          {activeNewRedux?.activeKey === 22 ? (
                            <Icon.FileEarmarkFill className="me-2" />
                          ) : (
                            <Icon.FileEarmark className="me-2" />
                          )}
                          Hospital Notice
                        </Accordion.Header>
                        <Accordion.Body className="d-flex flex-column">
                          {/* 
                        <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                13,
                                13.4,
                                "Notice Dashboard"
                              );
                              navigate("/dashboard/notice-dashboard");
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 13.4
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 13.4 ? (
                              <Icon.ClipboardDataFill className="me-2" />
                            ) : (
                              <Icon.ClipboardData className="me-2" />
                            )}
                            Notice Dashboard
                          </Button> */}

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                22,
                                22.1,
                                "Hospital Generate Notice"
                              );
                              navigate("/dashboard/hospital-generate-notice");
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 22.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 22.1 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Hospital Generate Notice
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                22,
                                22.2,
                                "Hospital Download Merge Notice"
                              );
                              navigate("/dashboard/hospital-download-notice");
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 22.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 22.2 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Hospital Download Merge Notice
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                22,
                                22.5,
                                "Hospital Compliance List"
                              );
                              navigate("/dashboard/hospital-compliance-list");
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 22.5
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 22.5 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Hospital Compliance List
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                22,
                                22.3,
                                "Hospital Compliance History"
                              );
                              navigate(
                                "/dashboard/hospital-compliance-history"
                              );
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 22.3
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 22.3 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Hospital Compliance History
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* -------------- School -----------------*/}
                      <Accordion.Item
                        eventKey={28}
                        className={
                          activeNewRedux?.activeKey === 28
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleNewAccordionChange(
                              28,
                              activeNewRedux.subActiveKey,
                              activeNewRedux.activeTab
                            );
                          }}
                        >
                          {activeNewRedux?.activeKey === 28 ? (
                            <Icon.FileEarmarkFill className="me-2" />
                          ) : (
                            <Icon.FileEarmark className="me-2" />
                          )}
                          School Notice
                        </Accordion.Header>
                        <Accordion.Body className="d-flex flex-column">
                          {/* 
                                              <Button
                                                  onClick={() => {
                                                    handleNewAccordionChange(
                                                      13,
                                                      13.4,
                                                      "Notice Dashboard"
                                                    );
                                                    navigate("/dashboard/notice-dashboard");
                                                  }}
                                                  className={
                                                    activeNewRedux?.subActiveKey === 13.4
                                                      ? "accordionNavActive mb-3"
                                                      : "accordionNav mb-3"
                                                  }
                                                >
                                                  {activeNewRedux?.subActiveKey === 13.4 ? (
                                                    <Icon.ClipboardDataFill className="me-2" />
                                                  ) : (
                                                    <Icon.ClipboardData className="me-2" />
                                                  )}
                                                  Notice Dashboard
                                                </Button> */}

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                28,
                                28.1,
                                "School Generate Notice"
                              );
                              navigate("/dashboard/school-generate-notice");
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 28.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 28.1 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            School Generate Notice
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                28,
                                28.2,
                                "School Download Merge Notice"
                              );
                              navigate("/dashboard/school-download-notice");
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 28.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 28.2 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            School Download Merge Notice
                          </Button>

                          {/* <Button
                                                  onClick={() => {
                                                    handleNewAccordionChange(
                                                      28,
                                                      28.5,
                                                      "School Compliance List"
                                                    );
                                                    navigate("/dashboard/school-compliance-list");
                                                  }}
                                                  className={
                                                    activeNewRedux?.subActiveKey === 28.5
                                                      ? "accordionNavActive mb-3"
                                                      : "accordionNav mb-3"
                                                  }
                                                >
                                                  {activeNewRedux?.subActiveKey === 28.5 ? (
                                                    <Icon.FileTextFill className="me-2" />
                                                  ) : (
                                                    <Icon.FileText className="me-2" />
                                                  )}
                                                  School Compliance List
                                                </Button>
                      
                                                <Button
                                                  onClick={() => {
                                                    handleNewAccordionChange(
                                                      28,
                                                      28.3,
                                                      "School Compliance History"
                                                    );
                                                    navigate(
                                                      "/dashboard/school-compliance-history"
                                                    );
                                                  }}
                                                  className={
                                                    activeNewRedux?.subActiveKey === 28.3
                                                      ? "accordionNavActive mb-3"
                                                      : "accordionNav mb-3"
                                                  }
                                                >
                                                  {activeNewRedux?.subActiveKey === 28.3 ? (
                                                    <Icon.FileTextFill className="me-2" />
                                                  ) : (
                                                    <Icon.FileText className="me-2" />
                                                  )}
                                                  School Compliance History
                                                </Button> */}
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* -------------- Notice -----------------*/}
                      <Accordion.Item
                        eventKey={13}
                        className={
                          activeNewRedux?.activeKey === 13
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleNewAccordionChange(
                              13,
                              activeNewRedux.subActiveKey,
                              activeNewRedux.activeTab
                            );
                          }}
                        >
                          {activeNewRedux?.activeKey === 13 ? (
                            <Icon.FileEarmarkFill className="me-2" />
                          ) : (
                            <Icon.FileEarmark className="me-2" />
                          )}
                          Notice
                        </Accordion.Header>
                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                13,
                                13.4,
                                "Notice Dashboard"
                              );
                              navigate("/dashboard/notice-dashboard");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 13.4
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 13.4 ? (
                              <Icon.ClipboardDataFill className="me-2" />
                            ) : (
                              <Icon.ClipboardData className="me-2" />
                            )}
                            Notice Dashboard
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                13,
                                13.1,
                                "Generate Notice"
                              );
                              navigate("/dashboard/generate-notice");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 13.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 13.1 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Generate Notice
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                13,
                                13.2,
                                "Download Merge Notice"
                              );
                              navigate("/dashboard/merge-pdf");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 13.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 13.2 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Download Merge Notice
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                13,
                                13.5,
                                "Notice Bajawali"
                              );
                              navigate("/dashboard/bill-bajawali");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 13.5
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 13.5 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Notice Bajawali
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                13,
                                13.3,
                                "Notice Formats"
                              );
                              navigate("/dashboard/notice-format");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 13.3
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 13.3 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Notice Formats
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={17}
                        onClick={() => {
                          handleNewAccordionChange(17, 17, "Allocate Target");
                          navigate("/dashboard/allocate-target");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 17 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 17 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Allocate Target
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={18}
                        onClick={() => {
                          handleNewAccordionChange(
                            18,
                            18,
                            "Japti Karvai History"
                          );
                          navigate("/dashboard/japti-karvai");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 18 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 18 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Japti Karvai History
                        </Accordion.Header>
                      </Accordion.Item>

                      {/* Complience */}
                      <Accordion.Item
                        eventKey={19}
                        className={
                          activeNewRedux?.activeKey === 19
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleNewAccordionChange(
                              19,
                              activeNewRedux.subActiveKey,
                              activeNewRedux.activeTab
                            );
                          }}
                        >
                          {activeNewRedux?.activeKey === 19 ? (
                            <Icon.FileEarmarkFill className="me-2" />
                          ) : (
                            <Icon.FileEarmark className="me-2" />
                          )}
                          Compliance
                        </Accordion.Header>

                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                19,
                                19.1,
                                "Compliance List"
                              );
                              navigate("/dashboard/compliance-list");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 19.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 19.1 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Compliance List
                          </Button>

                          <Button
                            onClick={() => {
                              handleNewAccordionChange(
                                19,
                                19.2,
                                "Compliance History"
                              );
                              navigate("/dashboard/compliance-history");
                              setnav();
                            }}
                            className={
                              activeNewRedux?.subActiveKey === 19.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeNewRedux?.subActiveKey === 19.2 ? (
                              <Icon.FileTextFill className="me-2" />
                            ) : (
                              <Icon.FileText className="me-2" />
                            )}
                            Compliance History
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* Delete Survey  */}
                      <Accordion.Item
                        eventKey={20}
                        onClick={() => {
                          handleNewAccordionChange(
                            20,
                            20,
                            "Delete Survey History"
                          );
                          navigate("/dashboard/delete-history");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 20 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 20 ? (
                            <Icon.FileEarmarkXFill className="me-2" />
                          ) : (
                            <Icon.FileEarmarkX className="me-2" />
                          )}
                          Delete Survey History
                        </Accordion.Header>
                      </Accordion.Item>

                      {/* Officer Report  */}
                      <Accordion.Item
                        eventKey={21}
                        onClick={() => {
                          handleNewAccordionChange(
                            21,
                            21,
                            "Officer Daily Report"
                          );
                          navigate("/dashboard/officer-daily-report");
                          setnav();
                        }}
                        className={
                          activeNewRedux?.activeKey === 21 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 21 ? (
                            <Icon.FileEarmarkXFill className="me-2" />
                          ) : (
                            <Icon.FileEarmarkX className="me-2" />
                          )}
                          Officer Daily Report
                        </Accordion.Header>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {userState?.userType_keyname === "mavim" && (
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>
              <Accordion.Item
                eventKey={1}
                onClick={() => {
                  handleAccordionChange(1, 1, "Dashboard");
                  navigate("/dashboard");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 1 ? (
                    <Icon.HouseDoorFill className="me-2" />
                  ) : (
                    <Icon.HouseDoor className="me-2" />
                  )}
                  Home
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(2, 2, "Manage Surveys");
                  navigate("/dashboard/manage-surveys");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 4 ? (
                    <Icon.BarChartFill className="me-2" />
                  ) : (
                    <Icon.BarChart className="me-2" />
                  )}
                  Manage Surveys
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={3}
                onClick={() => {
                  handleAccordionChange(3, 3, "Survey Images");
                  navigate("/dashboard/survey-images");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 3 ? (
                    <Icon.ImageFill className="me-2" />
                  ) : (
                    <Icon.Image className="me-2" />
                  )}
                  Survey Images
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={4}
                onClick={() => {
                  handleAccordionChange(4, 4, "Survey Image Verification");
                  navigate("/dashboard/survey-image-verification");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 4 ? (
                    <Icon.ShieldFillCheck className="me-2" size={17} />
                  ) : (
                    <Icon.ShieldCheck className="me-2" size={17} />
                  )}
                  Image Verification
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={5}
                onClick={() => {
                  navigate("/dashboard/image-verification-report");
                  handleAccordionChange(5, 5, "Sahyogini Image Verification");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 5 ? (
                    <Icon.ImageFill className="me-2" size={17} />
                  ) : (
                    <Icon.Image className="me-2" size={17} />
                  )}
                  Image Verify Report
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          )}

          {userState?.userType_keyname === "dataoperator" && (
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>
              <Accordion.Item
                eventKey={1}
                onClick={() => {
                  handleAccordionChange(1, 1, "Dashboard");
                  navigate("/dashboard");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 1 ? (
                    <Icon.HouseDoorFill className="me-2" />
                  ) : (
                    <Icon.HouseDoor className="me-2" />
                  )}
                  Home
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(2, 2, "Survey Images");
                  navigate("/dashboard/survey-images");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.ImageFill className="me-2" />
                  ) : (
                    <Icon.Image className="me-2" />
                  )}
                  Survey Images
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={3}
                onClick={() => {
                  handleAccordionChange(3, 3, "Survey Image Verification");
                  navigate("/dashboard/survey-image-verification");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 3 ? (
                    <Icon.ShieldFillCheck className="me-2" size={17} />
                  ) : (
                    <Icon.ShieldCheck className="me-2" size={17} />
                  )}
                  Image Verification
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={4}
                onClick={() => {
                  navigate("/dashboard/image-verification-report");
                  handleAccordionChange(4, 4, "Sahyogini Image Verification");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 4 ? (
                    <Icon.ImageFill className="me-2" size={17} />
                  ) : (
                    <Icon.Image className="me-2" size={17} />
                  )}
                  Image Verify Report
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          )}

          {(userState?.userType_keyname === "tso" ||
            userState?.userType_keyname === "clerk" ||
            userState?.userType_keyname === "fireofficer" ||
            userState?.userType_keyname === "pollutioncontroller") && (
            <Accordion flush defaultActiveKey={activeNewRedux?.activeKey}>
              <Accordion.Item
                eventKey={1}
                onClick={() => {
                  handleNewAccordionChange(1, 1, "Home");
                  navigate("/dashboard");
                  setnav();
                }}
                className={activeNewRedux?.activeKey === 1 ? " activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeNewRedux?.activeKey === 1 ? (
                    <Icon.HouseDoorFill className="me-2" />
                  ) : (
                    <Icon.HouseDoor className="me-2" />
                  )}
                  Home
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleNewAccordionChange(2, 2, "School Letter");
                  navigate("/dashboard/school-letter");
                  setnav();
                }}
                className={activeNewRedux?.activeKey === 2 ? " activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeNewRedux?.activeKey === 2 ? (
                    <Icon.EnvelopePaperFill className="me-2" />
                  ) : (
                    <Icon.EnvelopePaper className="me-2" />
                  )}
                  School Letter
                </Accordion.Header>
              </Accordion.Item>

              {/* <Accordion.Item
                        eventKey={14}
                        onClick={() => {
                          handleNewAccordionChange(14, 14, "Manage Surveys");
                          navigate("/dashboard/tso-manage-survey");
                        }}
                        className={
                          activeNewRedux?.activeKey === 14 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeNewRedux?.activeKey === 14 ? (
                            <Icon.PersonFill className="me-2" />
                          ) : (
                            <Icon.Person className="me-2" />
                          )}
                          Manage Surveys
                        </Accordion.Header>
                      </Accordion.Item> */}

              {/* - Notice -*/}
              <Accordion.Item
                eventKey={13}
                className={
                  activeNewRedux?.activeKey === 13
                    ? "activeMenu dropDown"
                    : "dropDown"
                }
              >
                <Accordion.Header
                  onClick={() => {
                    handleNewAccordionChange(
                      13,
                      activeNewRedux.subActiveKey,
                      activeNewRedux.activeTab
                    );
                  }}
                >
                  {activeNewRedux?.activeKey === 13 ? (
                    <Icon.FileEarmarkFill className="me-2" />
                  ) : (
                    <Icon.FileEarmark className="me-2" />
                  )}
                  Notice
                </Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button
                    onClick={() => {
                      handleNewAccordionChange(13, 13.4, "Notice Dashboard");
                      navigate("/dashboard/notice-dashboard");
                      setnav();
                    }}
                    className={
                      activeNewRedux?.subActiveKey === 13.4
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeNewRedux?.subActiveKey === 13.4 ? (
                      <Icon.ClipboardDataFill className="me-2" />
                    ) : (
                      <Icon.ClipboardData className="me-2" />
                    )}
                    Notice Dashboard
                  </Button>

                  <Button
                    onClick={() => {
                      handleNewAccordionChange(13, 13.1, "Generate Notice");
                      navigate("/dashboard/generate-notice");
                      setnav();
                    }}
                    className={
                      activeNewRedux?.subActiveKey === 13.1
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeNewRedux?.subActiveKey === 13.1 ? (
                      <Icon.FileTextFill className="me-2" />
                    ) : (
                      <Icon.FileText className="me-2" />
                    )}
                    Generate Notice
                  </Button>

                  <Button
                    onClick={() => {
                      handleNewAccordionChange(
                        13,
                        13.2,
                        "Download Merge Notice"
                      );
                      navigate("/dashboard/merge-pdf");
                      setnav();
                    }}
                    className={
                      activeNewRedux?.subActiveKey === 13.2
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeNewRedux?.subActiveKey === 13.2 ? (
                      <Icon.FileTextFill className="me-2" />
                    ) : (
                      <Icon.FileText className="me-2" />
                    )}
                    Download Merge Notice
                  </Button>

                  <Button
                    onClick={() => {
                      handleNewAccordionChange(13, 13.5, "Notice Bajawali");
                      navigate("/dashboard/bill-bajawali");
                      setnav();
                    }}
                    className={
                      activeNewRedux?.subActiveKey === 13.5
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeNewRedux?.subActiveKey === 13.5 ? (
                      <Icon.FileTextFill className="me-2" />
                    ) : (
                      <Icon.FileText className="me-2" />
                    )}
                    Notice Bajawali
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey={18}
                onClick={() => {
                  handleNewAccordionChange(18, 18, "Japti Karvai History");
                  navigate("/dashboard/japti-karvai");
                  setnav();
                }}
                className={activeNewRedux?.activeKey === 18 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeNewRedux?.activeKey === 18 ? (
                    <Icon.PersonFill className="me-2" />
                  ) : (
                    <Icon.Person className="me-2" />
                  )}
                  Japti Karvai History
                </Accordion.Header>
              </Accordion.Item>

              {/* Complience */}
              <Accordion.Item
                eventKey={19}
                className={
                  activeNewRedux?.activeKey === 19
                    ? "activeMenu dropDown"
                    : "dropDown"
                }
              >
                <Accordion.Header
                  onClick={() => {
                    handleNewAccordionChange(
                      19,
                      activeNewRedux.subActiveKey,
                      activeNewRedux.activeTab
                    );
                  }}
                >
                  {activeNewRedux?.activeKey === 19 ? (
                    <Icon.FileEarmarkFill className="me-2" />
                  ) : (
                    <Icon.FileEarmark className="me-2" />
                  )}
                  Compliance
                </Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button
                    onClick={() => {
                      handleNewAccordionChange(19, 19.1, "Compliance List");
                      navigate("/dashboard/compliance-list");
                      setnav();
                    }}
                    className={
                      activeNewRedux?.subActiveKey === 19.1
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeNewRedux?.subActiveKey === 19.1 ? (
                      <Icon.FileTextFill className="me-2" />
                    ) : (
                      <Icon.FileText className="me-2" />
                    )}
                    Compliance List
                  </Button>

                  <Button
                    onClick={() => {
                      handleNewAccordionChange(19, 19.2, "Compliance History");
                      navigate("/dashboard/compliance-history");
                      setnav();
                    }}
                    className={
                      activeNewRedux?.subActiveKey === 19.2
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeNewRedux?.subActiveKey === 19.2 ? (
                      <Icon.FileTextFill className="me-2" />
                    ) : (
                      <Icon.FileText className="me-2" />
                    )}
                    Compliance History
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          <div className="px-2">
            <Button className="LogoutBtn w-100" onClick={handleLogout}>
              {loader ? (
                <Spinner
                  animation="border"
                  variant="light"
                  className="spinner"
                />
              ) : (
                <>
                  <Icon.BoxArrowLeft className="me-2" />
                  Logout
                </>
              )}
            </Button>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default Menubar;
