import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
  Tab,
  Nav,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { setNewActiveKey } from "../Common/ActiveKeySlice";

const SchoolComplianceList = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state?.surveylogin?.userData);
  const [data, setdata] = useState();
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

    // filter states
    const [schoolName, setschoolName] = useState("");
    const [managerMobile, setMangerMobile] = useState("");

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getschoolComplianceData();
  }, [pageNo, docPerPage]);

  const getschoolComplianceData = async () => {
    setLoader(true);

    let payload = {
      schoolName: schoolName,
      managerMobile: managerMobile,
      documentsPerPage: docPerPage,
      page: pageNo,
    };
    await fetch(
      process.env.REACT_APP_BASE_URL + "/school/schoolComplianceList",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setdata(response);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        } else {
          setdata(null);
          setLoader(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const searchByMobile = () => {
    if(managerMobile && managerMobile.length === 10){
      getschoolComplianceData()
    }else{
      toast.error("Please Enter Valid Manager Mobile Number")
    }
  }

  const searchByschoolName = () => {
    if(schoolName){
      getschoolComplianceData()
    }else{
      toast.error("Please Enter school Name first")
    }
  }

  return (
    <div className="outletPadding">
      <Container>

      <Row className="mt-2">
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by school Name
              </Form.Label>{" "}
              <div className="d-flex">
                <Form.Control
                  placeholder="Enter school Name"
                  value={schoolName}
                  className="me-3"
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[A-Za-z ]+$/)
                    ) {
                      setschoolName(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setschoolName(e.target.value);
                    }
                  }}
                />
                <Button className="primaryBtn" onClick={searchByschoolName}>
                  <Icon.Search className="me-2" size={15} />
                  Search
                </Button>
              </div>
            </Card>
          </Col>

          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by Manager Mobile
              </Form.Label>{" "}
              <div className="d-flex">
                <Form.Control
                  placeholder="Enter Mobile No."
                  value={managerMobile}
                  className="me-3"
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[0-9]+$/) &&
                      e.target.value.length <= 10
                    ) {
                      setMangerMobile(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setMangerMobile(e.target.value);
                    }
                  }}
                />
                <Button className="primaryBtn" onClick={searchByMobile}>
                  <Icon.Search className="me-2" size={15} />
                  Search
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-2">
          <div className="table-wrapper" style={{ maxHeight: "60vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>school Name</th>
                <th>Zone</th>
                <th>Managing Person Name</th>
                <th>Managing Person Mobile</th>
                
                <th>Action</th>
              </thead>
              {loader ? (
                <Skeleton rows={8} cols={8} />
              ) : data !== null &&
                data !== undefined &&
                data?.data.length > 0 ? (
                data?.data.map((itm, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {pageNo !== 1 ? (
                          <div className="d-flex">
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </div>
                        ) : (
                          <div className="d-flex">{index + 1}</div>
                        )}
                      </td>
                      <td>{itm?.schoolName ? itm?.schoolName : "-"}</td>
                      <td>{itm?.zone ? itm?.zone : "-"}</td>
                      <td>{itm?.managerName ? itm?.managerName : "-"}</td>
                      <td>{itm?.managerMobile ? itm?.managerMobile : "-"}</td>
                      <td>
                        <Button
                          className="primaryBtn ms-2"
                          onClick={() => {
                            navigate("/dashboard/school-add-compliance", {
                              state: itm.schoolId,
                            });
                          }}
                        >
                          Add Compliance
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>

          {!loader && (
            <Row>
              <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                <h6 className="text-start mb-0">
                  <Icon.People className="me-2" />
                  Total - <strong>{data?.count}</strong>
                </h6>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Pagination
                  currentPage={pageNo}
                  totalPages={noOfPages}
                  onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage}
                  docsPerPage={docPerPage}
                />
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SchoolComplianceList;
